@font-face {
  font-family: 'Neue Machina';
  font-weight: 100;
  src: local('Neue Machina'), url(./assets/fonts/NeueMachina-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Neue Machina';
  font-weight: 400;
  src: local('Neue Machina'), url(./assets/fonts/NeueMachina-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Neue Machina';
  font-weight: 700;
  src: local('Neue Machina'), url(./assets/fonts/NeueMachina-Ultrabold.otf) format('opentype');
}

@font-face {
  font-family: 'Epilogue';
  font-weight: 100;
  src: local('Epilogue'), url(./assets/fonts/Epilogue-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Epilogue';
  font-weight: 700;
  src: local('Epilogue'), url(./assets/fonts/Epilogue-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html {
  scroll-behavior: smooth;
}

@keyframes rotateElement1 {
  0% {
    transform: rotate(0);
    opacity: 0;
  }
  100% {
    transform: rotate(15deg);
    opacity: 1;
  }
}

@keyframes rotateElement2 {
  0% {
    transform: rotate(5deg);
    opacity: 0;
  }
  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateElement3 {
  0% {
    transform: rotate(0);
    opacity: 0;
  }
  100% {
    transform: rotate(-8deg);
    opacity: 1;
  }
}

@keyframes iconeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ondaSx {
  animation: 1.8s ease 0s 1 slideInFromLeft;
}

.ondaDx {
  animation: 1.8s ease 0s 1 slideInFromRight;
}

.el1 {
  transform-origin: top;
  animation: 1.8s ease 0s 1 rotateElement1;
  transform: rotate(10deg);
}

.el2 {
  transform-origin: top;
  animation: 1.8s ease 0s 1 rotateElement2;
  transform: rotate(0deg);
}

.el3 {
  transform-origin: top right;
  animation: 1.8s ease 0s 1 rotateElement3;
  transform: rotate(-6deg);
}

.mano {
  max-width: 556px;
  z-index: 2
}

.ondaMobile {
  position: absolute;
  bottom: 0;
}

.icone {
  animation: 2s ease 0s 1 iconeIn;
}


@media screen and (max-width: 767px) {
  .mano {
    max-height: 38vh;
    max-width: 100%;
    left: 0;
  }


}

@media (min-resolution: 120dpi) {
  html {
    font-size: 80%;
  }
}


.privacy-container {
  margin-top: 6.25rem;
}

.privacy-container p {
  text-align: left;
  opacity: 0.5;
}

.privacy-container .privacy-title {
  font-size: 1.25rem;
}

.privacy-container .privacy-subtitle {
  font-size: 1.1rem;
}

.privacy-container .privacy-title, .privacy-container .privacy-subtitle {
  opacity: 1;
  color: #dedede;
}

.triangoloEq {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid white;
  transform: translate(-75px, 430px) rotate(-90deg);
}

.triangoloEq2 {
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid white;
  transform: translate(430px, 50px) rotate(90deg);
}

.mask2 {
  mask-image: radial-gradient(ellipse, black 50%, rgba(0, 0, 0, 0.5) 50%);
}